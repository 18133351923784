@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "inter-regular"; /* 400 */
  src: url(./asset/fonts/Inter-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "inter-medium"; /* 500 */
  src: url(./asset/fonts/Inter-Medium.ttf) format("truetype");
}

/* Quicksand */
@font-face {
  font-family: "quicksand-light";
  src: url(./asset/fonts/Quicksand-Light.ttf) format("truetype");
}
@font-face {
  font-family: "quicksand-regular"; /* 400 */
  src: url(./asset/fonts/Quicksand-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "quicksand-medium"; /* 500 */
  src: url(./asset/fonts/Quicksand-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "quicksand-semiBold"; /* 600 */
  src: url(./asset/fonts/Quicksand-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "quicksand-bold"; /* 700 */
  src: url(./asset/fonts/Quicksand-Bold.ttf) format("truetype");
}

/* .containers{
  width: 70%;
}

@media (max-width:768px){
  .containers{
    width: 90%;
  }
} */

body{
  background-color: #edf2f4;
  color: #2b2d42;
}