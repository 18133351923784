
.map-container iframe{
    border-radius: 20px;
    width: 100%;
    height: 100%;
}

@media(min-width:1440px) and (max-width:1600px){
    .header-container .header-wrapper{
        width: 600px;
    }
}
@media(min-width:1440px){
    .landing-card-container-2{
        display: none;
    }
}
@media(min-width:768px) and (max-width:1439px){
    .landing-card-container{
        display: none;
    }
    
}
@media(min-width:1024px) and (max-width:1439px){
    .header-container .header-wrapper{
        width: 600px;
    }
    .about-containers .about-wrapper{
        padding: 30px;
    }
    .about-containers .about-heading{
        width: auto;
        font-size: 36px;
        line-height: 40px;
    }
    .about-containers .description{
        width: 100%;
    }
  
}
@media (min-width:768px) and (max-width:1024px){
    .map-container iframe{
        height: 350px;
    }
}
@media (max-width:767px){
    .map-container iframe{
        height: 250px;
    }
}